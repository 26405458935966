export const translation_ptBR = {
  diveEditDashboard: "Mergulhe na edição de dashboards.",
  passwordNotMatch: "A senha não corresponde",
  successChangedPassword: "Sucesso! Você mudou sua senha",
  currentPassword: "Senha atual",
  newPassword: "Nova Senha",
  confirmNewPassword: "Confirme a nova senha",
  updatePassword: "Atualizar senha",
  pleaseInformPhoneNumber: "Por favor, informe seu telefone.",
  dataEditedSuccess: "Dados editados com sucesso",
  myData: "Meus dados",
  readingModePleaseContact:
    "Usuário Somente Leitura. Entre em contato com o usuário administrador da sua empresa para acessar as funcionalidades de edição",
  youCanOnlyChangeItems:
    "Você pode alterar apenas os itens que você mesmo criou.",
  navBar: {
    operationInsight: "Insights Operacionais",
    forecastCharts: "Previsões",
    sensorCharts: "Sensores",
    dynamicUkcCharts: "FAQ Dinâmica",
    alerts: "Alertas",
    mooringLines: "Amarração de navios",
    morphologyMap: "Mapa de morfologia",
    admin: "Admin",
    myData: "Meus dados",
    changePassword: "Mudar senha",
    userManager: "Gestão de usuários",
    notificationManager: "Gerenciador de notificações",
    forecastChecker: "Verificador de previsão",
    settings: "Configurações",
    errorWhileGetMooringAuthentication:
      "Oops! Erro ao obter autenticação de ancoragem! Entre em contato conosco.",
    logout: "Sair",
    portuguese: "Português",
    english: "Inglês",
    shareForecast: "Compartilhar previsão",
    new: "Novo",
    shareForecastWithOutLeaving: "Compartilhe a previsão sem sair do i4cast!",
    reportOccurrence: "Reportar ocorrência",
    occurrences: "Ocorrências",
    importOccurrence: "Importar ocorrência",
    youAreGoingToBeRedirect: "Você será redirecionado para outra página",
    errorWhileGetUKCAuthentication:
      "Oops! Erro ao obter autenticação do FAQ dinâmico! Entre em contato conosco.",
    manageStation: "Gestão de estações",
    performance: "Performance",
    eventHistory: "Histórico de eventos",
    eventAnalysis: "Análise de eventos",
    watchTutorial: "Assistir tutorial",
    learnHowToUse: "Aprenda a Utilizar o i4cast",
  },
  login: {
    usernameNoLongerValid:
      "Desculpe, esse usuário foi desativo e não é mais valido",
    passwordNotBeenChanged:
      "A senha não foi alterada. Repita a operação ou entre em contato com contato@i4sea.com",
    errorValidatingPassword: "Erro ao validar a sua senha atual",
    resetPassword: "Recuperar senha",
    logIn: "Log in",
    verificationCodeSent: "Código de verificação enviado!",
    successWeveSentYouEmailPasswordReset:
      "Sucesso! Enviamos um e-mail para você com instruções de redefinição de senha.",
    doneNewPasswordSaved: "Feito! Nova senha salva",
    successGoBackToLogin:
      "Sucesso! Retorne para o login e seja bem-vindo de volta.",
    login: {
      usernameNoLongerValid:
        "Desculpe, esse usuário foi desativo e não é mais valido",
      updateI4castData: "Atualize seus dados para maximizar o uso do i4cast",
      resetPassword: "Recuperar senha",
      gotIt: "Entendi!",
      skip: "pular",
      emailUsername: "Email ou usuário",
      password: "Senha",
      keepLogged: "Mantenha-me conectado",
      userPasswordIncorrect:
        "O usuário ou a senha não estão corretos. Recuperar a sua ",
      logIn: "Entrar",
      forgotUsername: "Esqueci o usuário?",
      forgotPassword: "Esqueceu a senha?",
      alert: "Alerta",
      verificationCodeSent: "Código de verificação enviado!",
      successWeveSentYouEmailPasswordReset:
        "Sucesso! Enviamos um e-mail para você com instruções de redefinição de senha.",
      doneNewPasswordSaved: "Feito! Nova senha salva",
      successGoBackToLogin:
        "Sucesso! Retorne para o login e seja bem-vindo de volta.",
      itAppearsYouAreDisconnected:
        "Parece que você está desconectado. Por favor, verifique sua conexão com a internet.",
      sorryWeSeemToHaveEncountered:
        "Desculpe-nos, parece que encontramos um problema em nosso servidor. Por favor, tente novamente mais tarde.",
      hidePassword: "Esconder senha",
      showPassword: "Mostrar senha",
      eyesClosed: "Olho fechado",
      eyesOpen: "Olho aberto",
    },
    forgotPassword: {
      recoverPassword: "recuperar senha",
      next: "Próximo",
      cancel: "Cancelar",
      thereWasProblem: "Ocorreu um problema",
      pleaseInputEmail: "Por favor, insira seu e-mail!",
      updatePassword: "Atualizar senha",
      newPassword: "Nova Senha",
      pleaseCheckEmail: "Por favor, verifique sua caixa de e-mail",
      enterCodeVerification: "Digite seu código de verificação",
      pleaseInputVerificationCode: "Insira seu código de verificação",
      pleaseInputYourPassword: "Por favor insira sua senha",
      pleaseInputYourNewPassword: "Por favor insira sua nova senha",
      pleaseInputYourNewPasswordAgain: "Insira sua nova senha novamente",
      confirmNewPassword: "Confirme a nova senha",
    },
    changePassword: {
      changePassword: "Mudar senha",
      updatePassword: "Atualizar senha",
      newPassword: "Nova Senha",
      cancel: "Cancelar",
      dataEditedSuccess: "Dados editados com sucesso",
      currentPassword: "Senha atual",
      pleaseInputCurrentPassword: "Por favor insira sua senha atual",
      pleaseInputYourNewPassword: "Por favor insira sua nova senha",
      pleaseInputYourNewPasswordAgain: "Insira sua nova senha novamente",
      confirmNewPassword: "Confirme a nova senha",
      passwordNotMatch: "A senha não corresponde",
      success: "Successo",
      error: "Erro",
    },
    newPasswordForm: {
      verificationCode: "Código de verificação",
      newPassword: "Nova Senha",
      resetPassword: "Redefinir senha",
      returnToLogin: "Voltar ao login",
      verificationCodePasswordIncorrect:
        "Código de verificação ou senha incorretos.",
    },
    recoverPasswordForm: {
      typeYourEmail: "Digite seu e-mail",
      sendInstructionsByEmail: "Enviar instruções por e-mail",
      emailIncorrect: "E-mail incorreto.",
      returnToLogin: "Voltar ao login",
    },
    signIn: {
      alert: "Alerta",
      updateI4castData: "Atualize seus dados para maximizar o uso do i4cast",
      gotIt: "Entendi!",
      skip: "pular",
      thereWasAProblemWithLogin:
        "There was a problem with the login, check your credentials.",
      user: "Do utilizador",
      password: "Senha",
      logIn: "Conecte-se",
      forgotPassword: "Esqueceu a senha?",
    },
  },
  notificationManager: {
    pleaseEnterMessageTitle: "Insira um título de mensagem.",
    pleaseEnterMessageContent: "Por favor insira um conteúdo de mensagem.",
    confirm: "confirme",
    confirmPushNotificationSent:
      "Confirmar notificação push enviada para {{totalUsers}} usuários-alvo?",
    send: "Mandar",
    cancel: "Cancelar",
    selectCompany: "Selecione uma empresa",
    next: "Próximo",
    previous: "Anterior",
    sendPushNotification: "Enviar notificação push",
    pushNotificationSentSuccessfully: "Notificação push enviada com sucesso",
    submissionFailed: "Falha no envio",
    checkModifingInformationResubmiting:
      "Verifique e modifique as seguintes informações antes de reenviar.",
    tryAgain: "Tente novamente",
    allClearYouDontHaveNewMessages:
      "Tudo limpo! Você não tem nenhuma mensagem nova.",
    unreadNotification: "Notificação não lida",
    markAllAsRead: "Marcar tudo como lido.",
    notifications: "Notificações",
    sendNewPushes: "Enviar novos pushs",
  },
  oceanWeatherIntelligence: {
    errorGeneratingInsight:
      "Ops! Houve um problema ao gerar os insights para essa localização e alguns deles ficaram inacessíveis. Por favor entre em contato para mais informações",
    errorGetChartData:
      "Erro ao buscar dados para o gráfico. Por favor entre em contato.",
    environmentalVariablesNotFound:
      "Ooops! Variáveis ambientais não encontradas para essa localização!",
    insightIsCausedBy: "O insight é causado por:",
    end: "Fim",
    start: "Início",
    summary: "Resumo",
    oceanWeatherIntelligence: {
      clickGoBackPastInsights: "Histórico de insights",
      needSelectDashboardPastInsight:
        "Ops! Você precisa selecionar um dashboard para voltar à previsão anterior",
      diveOperationInsights: "Mergulhe nos insights operacionais.",
      editDashboard: "Editar dashboard",
      selectDashboard: "Selecione um dashboard",
      needSelectDashboardAddLocation:
        "Ops! Você precisa selecionar um dashboard para adicionar um local",
      editLocation: "Editar localização",
      editInsight: "Editar insight",
      currentInsight: "Insight atual",
      videoTutorials: "Vídeos tutoriais",
      time: "Horário:",
      operationInsights: "Insights operacionais",
      selectDashboardInsight:
        "Selecione um dashboard para visualizar seus insights",
      dashboards: "Dashboards",
      locations: "Localizações",
      insights: "Insights",
      weStillDontHaveAnyDashboard:
        "Ainda não temos nenhum dashboard cadastrado.",
      letsGo: "Vamos lá?",
      addLocation: "Adicionar Localização",
      addLocationInsightToYourDashboard:
        "Para visualizar o Gráfico adicione uma localização. Clique em 'Adicionar Localização' para começar. 😉",
      almostThereCreateInsightVisualizeChart:
        "Quase lá! Para criar um Insight e visualizar o gráfico",
      clickHere: "clique aqui",
    },
    insightAlert: {
      alertRemovalFailed: "A remoção do alerta falhou. Contate-nos",
      selectDashboardFilterLocation:
        "Selecione um dashboard para filtrar a localização",
      alert: "Alerta",
      insight: "Insight",
      location: "Localização",
      dashboard: "Dashboard",
      filterDashboard: "Selecionar um dashboard",
      filterLocation: "Selecionar um local",
      createNewAlert: "Criar novo alerta",
      delete: "Deletar",
      selectRecipient: "Selecione um destinatário",
      users: "Usuários",
      userName: "usuário",
      addAlert: "Adicionar alerta",
      editAlert: "Editar Alerta",
      add: "Adicionar",
      edit: "Editar",
      cancel: "Cancelar",
      alertName: "Nome do Alerta",
      pleaseInputAlertName: "Insira o nome do alerta!",
      alertNameExceedCharactersLimit:
        "O nome do alerta excede o limite de 100 caracteres!",
      status: "Status",
      selectDashboardFromList: "Selecione um dashboard da lista!",
      selectLocationFromList: "Selecione um local da lista!",
      selectInsightFromList: "Selecione um insight da lista!",
      upTo: "Tempo de antecedência",
      selectTimeBeforeEvent: "Selecione um horário antes do evento!",
      minutes: "minutos",
      hour: "hora",
      hours: "horas",
      day: "dia",
      days: "dias",
      notificationName: "Nome da Notificação",
      notificationNameExceedCharactersLimit:
        "O nome da notificação excede o limite de 100 caracteres!",
      description: "Descrição",
      inputLocationSummary: "Insira o resumo do local",
      minimunEventDuration: "Duração mínima do evento",
      eventTimeRange: "Intervalo de horas do evento",
      insightLocationTimezone: "*Timezone da localização do insight",
      otherConfigurationOptions: "Outras opções de configuração",
      configurationCommingSoon: "Esta configuração em breve",
      eventStart: "Início do evento",
      eventFinish: "Fim do evento",
      recipient: "Destinatário",
      errorGetUserAlert:
        "Erro ao buscar dados do usuário. Por favor entre em contato",
      errorGetDashboardAlert:
        "Erro ao buscar dados do dashboard. Por favor entre em contato",
      errorGetLocationAlert:
        "Erro ao buscar dados da localização. Por favor entre em contato",
      errorGetInsightAlert:
        "Erro ao buscar dados do insight. Por favor entre em contato",
      errorGetAlertsData:
        "Erro ao buscar dados do alerta. Por favor entre em contato",
      errorDeleteAlerts: "Erro ao deletar alerta. Por favor entre em contato",
      errorAddAlert: "Erro ao adicionar alerta. Por favor entre em contato",
      errorUpdateAlert: "Erro ao atualizar alerta. Por favor entre em contato",
      insightAlerts: "Alertas de insights",
      beforeEventsStart: "Antes do início do evento",
      notification: "Notificação",
      areYouSureWantDeleteThisAlert:
        "Tem certeza de que deseja excluir este alerta?",
      allUsers: "Todos usuários",
      yes: "Sim",
      no: "Não",
    },
    operationRule: {
      createDashboard: "Criar um novo dashboard",
      youNeed4AlphaNumericToTheKey:
        "Ops! Você precisa usar 4 caracteres alfanuméricos para a chave. ex: ABCD, ABC1, A1BC",
      informNameAndKeyForAdd:
        "Informe o nome e a chave para adicionar um novo dashboard.",
      informNameAndKeyForEdit: "Informe o nome e chave para edição.",
      removalFailedContactUs: "A remoção falhou. Contate-nos",
      diveIntoEditDashboard: "Mergulhe na edição de dashboards.",
      operationInsight: "Insight operacionais",
      dashboard: "Dashboard",
      name: "Nome",
      key: "Chave",
      pleaseInformNameOfDashboard: "Informe o nome do dashboard.",
      informDashboardName: "Nome do dashboard",
      pleaseInformKeyOfDashboard: "Informe a chave do dashboard.",
      add: "Adicionar",
      newNameDashboard: "Novo nome do dashboard",
      newKey: "Nova chave",
      save: "Salvar",
      cancel: "Cancelar",
      edit: "Editar",
      areYouSureWantDeleteDashaboard:
        "Tem certeza de que deseja excluir este dashboard?",
      delete: "Excluir",
      videoTutorials: "Vídeos tutoriais",
      errorToGetData:
        "Erro ao obter dados. Por favor, entre em contato conosco.",
      errorToAddData:
        "Erro ao adicionar dados. Por favor, entre em contato conosco.",
      needToInformIdForUpdateData:
        "Necessário informar ID para dados de atualização",
      errorToUpdateData:
        "Erro ao atualizar dados. Por favor, entre em contato conosco.",
      needInformIdForDeleteData: "Necessário informar ID para deletar dados",
      errorToDeleteData:
        "Erro ao excluir dados. Por favor, entre em contato conosco.",
      yes: "Sim",
      no: "Não",
      startCreatingFirstInsight:
        "Comece a criar o seu primeiro Insight adicionando um dashboard",
      editDashboard: "Editar dashboard",
      deleteDashboard: "Excluir Dashboard",
      areYouSureCantUndoThis:
        "Você tem certeza? {{dashboardName}} será removido!",
      newDashboard: "Novo Dashboard",
      search: "Pesquisar",
      dashboardSuccessOnSave: "Sucesso! Seu dashboard foi salvo",
    },
    operationRuleLocation: {
      youNeedSelectDashboardToAddLocation:
        "Ops! Você precisa selecionar um dashboard para adicionar um local",
      locationRemovalFailedContactUs: "A remoção do local falhou. Contate-nos",
      editLocation: "Editar localização",
      operationInsight: "Insights operacionais",
      location: "Localização",
      diveEditDashboard: "Mergulhe no dashboard de edição.",
      selectDashboard: "Selecione um dashboard",
      addNewLocation: "Adicionar novo local",
      edit: "Editar",
      areYouSureWantDeleteLocation:
        "Tem certeza de que deseja excluir este local?",
      yes: "Sim",
      no: "Não",
      delete: "Excluir",
      videoTutorials: "Vídeos tutoriais",
      add: "Adicionar",
      cancel: "Cancelar",
      youNeed4AlphaNumericToTheKey:
        "Ops! Você precisa usar 4 caracteres alfanuméricos para a chave. ex: ABCD, ABC1, A1BC",
      name: "Nome",
      pleaseInputLocationName: "Por favor insira o nome do local!",
      key: "Chave",
      pleaseInputInitialNameLocation: "Insira o nome inicial do local!",
      inputLocationSummary: "Insira a descrição do local",
      region: "Região",
      pleaseSelectRegionFromList: "Selecione uma região",
      station: "Estação",
      pleaseSelectStationFromList: "Selecione uma estação",
      errorGetLocationData:
        "Erro ao buscar dados da localização. Por favor entre em contato.",
      errorAddLocationData:
        "Erro ao adicionar localização. Por favor entre em contato.",
      errorUpdateLocationData:
        "Erro ao atualizar localização. Por favor entre em contato.",
      errorDeleteLocationData:
        "Erro ao deletar localização. Por favor entre em contato.",
      summary: "Descrição",
      addNewLocationToYourDashboard:
        "Adicione uma nova localização para o seu dashboard.",
      deleteLocation: "Excluir Location",
      areYouSureCantUndoThis:
        "Você tem certeza? {{locationName}} será removido!",
      save: "Salvar",
      youNeedToFillAllTheFields: "Você precisa preencher todos os campos",
      locationSuccessOnSave: "Sucesso! Sua localização foi salva",
    },
    operationRuleLocationInsight: {
      youNeedSelectLocationToEditInsight:
        "Ops! Você precisa selecionar um local para editar um insight",
      locationRemovalFailedContactUs: "A remoção do local falhou. Contate-nos",
      youNeedSelectLocationToAddInsight:
        "Ops! Você precisa selecionar um local para adicionar um novo insight",
      editInsight: "Editar Insights",
      operationInsight: "Insights operacionais",
      insight: "Insight",
      diveEditDashboard: "Mergulhe na edição de dashboards.",
      selectLocation: "Selecione um local",
      addNewInsight: "Adicionar novo insight",
      edit: "Editar",
      areYouSureWantDeleteInsight:
        "Tem certeza de que deseja excluir este insight?",
      delete: "Excluir",
      videoTutorials: "Vídeos tutoriais",
      informGroupConditionInsight:
        "Ops! Informe um grupo de pessoas sobre esse insight.",
      informConditionGroupsInsight:
        "Ops! Informe uma condição nos grupos deste insight.",
      errorCriticalUpdateGroupConditions:
        "Erro crítico nas condições do grupo de atualização. Por favor, entre em contato conosco!",
      add: "Adicionar",
      name: "Nome",
      restrictionType: "Restritivo",
      noRestrictionType: "Permissivo",
      insightType: "Tipo de insight",
      tooltipRestrictionType:
        "Ativo: Insight com condições para eventos restritivo.  Inativo: Insight com condições para eventos permissivo",
      pleaseInputInsightName: "Insira o nome do insight!",
      stopNavigationForSmallBoats: "Parar a navegação para pequenos barcos",
      high: "Alto",
      medium: "Médio",
      low: "Baixo",
      pleaseEnterSummary: "Insira um descrição",
      theNavigationShouldStoppedWindsTooStrong:
        "A navegação deve ser interrompida porque os ventos estão muito fortes",
      conditions: "Condições",
      conditionsInsideConditionGroupShouldHaveSameLogicalOperator:
        "As condições dentro do grupo de condições devem ter o mesmo operador lógico. Se você quiser operadores lógicos diferentes, adicione outro grupo",
      pleaseSelectEnvironmentalVariable: "Selecione uma variável ambiental",
      isGreaterThan: "é maior que",
      isEqualToOrGreaterThan: "é igual ou maior que",
      isEqualTo: "é igual a",
      isLessThan: "é menor que",
      isEqualToOrLessThan: "é igual ou menor que",
      pleaseEnterValue: "Por favor, insira o valor!",
      addNewCondition: "Nova condição",
      removeGroup: "Remover grupo",
      addNewGroup: "Novo grupo",
      errorWhileGetDataForInsights:
        "Erro ao tentar obter dados para insights. Por favor, entre em contato conosco.",
      errorWhileAddInsight:
        "Erro ao tentar adicionar insight. Por favor, entre em contato conosco.",
      internalErrorToUpdateData:
        "Erro interno ao atualizar os dados. Por favor, entre em contato conosco.",
      errorDeleteInsight:
        "Erro ao excluir o insight. Por favor, entre em contato conosco.",
      yes: "Sim",
      no: "Não",
      cancel: "Cancelar",
      error: "Erro",
      details: "Detalhes",
      priority: "Prioridade",
      summary: "Descrição",
      location: "Localização",
      and: "E",
      or: "OU",
      weReAlmostTheNowRegisterInsight:
        "Estamos quase lá! Agora você só precisa cadastrar o seu primeiro insight",
      group: "Grupo",
      type: "Tipo",
      youNeedToFillAllTheFields:
        "Você precisa preencher todos os campos obrigatórios",
      addNewConditionGroupInsight:
        "Adicione um novo grupo de condições climáticas para cadastrar seu insight",
      save: "Salvar",
      andVerifyConditionTrueOrVerifyAtLeastConditionTrue:
        "E: Verifica se todas condições são verdadeiras. OU: Verifica se pelo menos uma das condições é verdadeira.",
      groupsAreConditionsAddOneConditions:
        "Os grupos são conjuntos de condições climáticas. Adicione pelo menos uma condição climática para cada grupo criado",
      nameRequired: "Nome é obrigatório",
      summaryRequired: "Resumo é obrigatório",
      valueRequired: "Valor é obrigatório",
      insightSuccessOnSave: "Sucesso! Seu Insight foi salvo",
      deleteGroup: "Excluir grupo",
      areYouSureWantDeleteGroup: "Tem certeza que deseja excluir esse grupo ?",
    },
    insightManager: {
      insightManager: "Gerenciador de Insights",
      dashboards: "Dashboards",
      locations: "Localizações",
      insights: "Insights",
    },
  },
  registerUser: {
    errorWhileLoadUserAreaOptions:
      "Erro ao carregar as opções da área do usuário",
    youHaveSuccessfullyRegistered:
      "Uhuuul! Você foi cadastrado com sucesso e já pode realizar o seu login!",
    errorWhileRegisterUser: "Erro ao cadastrar usuário",
    expiredInvitation: "convite expirado",
    sorryThePageYouVisitedNotExist:
      "Desculpe, a pagina que você visitou não existe mais",
    youInvitedJoinI4cast: "Você foi convidado para se juntar ao i4cast®!",
    invitedBy: "Convidado por",
    name: "Nome",
    pleaseInputYourName: "Por favor informe seu nome",
    nameMustContainOnlyLetters: "Nome deve conter apenas letras",
    lastName: "Sobrenome",
    pleaseInputYourLastName: "Por favor informe seu sobrenome",
    lastNameMustContainOnlyLetters: "Sobrenome deve conter apenas letras",
    username: "Usuário",
    pleaseInputYourUsername: "Por favor informe seu usuário!",
    lastNameMustStartWithLetter:
      "Deve começar com uma letra, conter apenas caracteres alfanuméricos, '_' e '.",

    usernameAlreadyHasAccount: "Usuário já possui uma conta no i4cast",
    password: "Senha",
    pleaseInputPassword: "Por favor informe sua senha!",
    passwordMustContain: "Deve conter pelo menos 6 dígitos e uma letra",
    phone: "Telefone",
    jobArea: "Setor de trabalho",
    jobPosition: "Área de trabalho",
    register: "CADASTRAR",
    errorInInvitation: "Erro no convite",
  },
  settings: {
    unitMeasurement: "Unidade de medida",
    success: "Sucesso!",
    yourChangesHaveSaved: "Suas alterações foram salvas.",
    changingUnitStillNotAppliedOperationInsights:
      "Trocar unidades de medida ainda não está disponivel na funcionalidade dos Insights operacionais",
    wind: "VENTO",
    knots: "nós",
    ms: "m/s",
    mph: "mph",
    kmh: "km/h",
    current: "CORRENTE",
    wave: "ONDA",
    m: "m",
    ft: "pés",
    waterLevel: "NÍVEL D'ÁGUA",
    cm: "cm",
    precipitation: "CHUVA",
    mm: "mm",
    inches: "polegadas",
    settings: "Configurações",
    selectHowYouWantToSee: "Selecione como deseja visualizar",
  },
  admin: {
    changePassword: {
      passwordNotMatch: "A senha não corresponde",
      successChangedPassword: "Sucesso! Você mudou sua senha",
      currentPassword: "Senha atual",
      newPassword: "Nova Senha",
      confirmNewPassword: "Confirme a nova senha",
      updatePassword: "Atualizar senha",
    },
    myData: {
      pleaseInformPhoneNumber: "Por favor, informe seu telefone.",
      dataEditedSuccess: "Dados editados com sucesso",
      myData: "Meus dados",
      name: "Nome",
      pleaseInputYourName: "Por favor, insira seu nome!",
      middleName: "Nome do meio",
      lastName: "Sobrenome",
      pleaseInputYourLastName: "Por favor, insira seu sobrenome!",
      changePassword: "Mudar senha",
      email: "Email",
      pleaseInputYourEmail: "Por favor, insira seu e-mail!",
      selectYourJobPosition: "Selecione seu cargo",
      pleaseSelectJobPosition: "Por favor, selecione o seu cargo na lista!",
      selectYourJobArea: "Selecione seu setor de trabalho",
      pleaseSelectJobArea: "Selecione seu setor de trabalho na lista!",
      edit: "Editar",
      portuguese: "Português",
      english: "inglês",
      errorGetUserData:
        "Erro ao buscar dados do usuário. Por favor entre em contato.",
      errorUpdateUserData:
        "Erro ao atualizar dados do usuário. Por favor entre em contato",
      errorGetUserPositionData:
        "Erro ao buscar dados da posição do usuário. Por favor entre em contato",
      errorGetUserAreaData:
        "Erro ao buscar dados da área do usuário. Por favor entre em contato",
      success: "Successo",
      errorUpdateUserLanguage:
        "Erro ao atualizar idioma do usuário. Por favor entre em contato",
      error: "Erro",
      phone: "Telefone",
    },
    userManager: {
      yourInviteSuccessfullySent:
        "Viva! Seu convite foi enviado com sucesso e um novo usuário está chegando!",
      errorWhileSendInvitation:
        "Erro ao tentar enviar o convite. Entre em contato conosco.",
      userPermissionGroupSuccessfully:
        "Grupo de permissão de usuário aplicado com sucesso",
      errorWhileEditUser:
        "Erro ao tentar editar o usuário. Por favor, entre em contato conosco.",
      userDataNotFoundOnList:
        "Dados do usuário não encontrados na lista. Por favor, entre em contato conosco.",
      userDeactivatedSuccessfully: "Usuário desativado com sucesso",
      userReactivatedSuccessfully: "Usuário reativado com sucesso",
      errorWhileDeactivateUser:
        "Erro ao tentar desativar o usuário. Entre em contato conosco.",
      errorWhileReactivateUser:
        "Erro ao tentar reativar o usuário. Entre em contato conosco.",
      userManager: "Gerenciador de usuários",
      inviteUser: "Convidar Usuário",
      inviteNewUserFromOrganization:
        "Convide um novo usuário da sua empresa para acessar ao i4cast®!",
      invite: "Convidar",
      thisIsStillNotValidEmail: "Este ainda não é um e-mail válido!",
      pleaseInputYourEmail: "Por favor, insira seu e-mail!",
      emailAlreadyHasi4cast: "O e-mail já possui uma conta i4cast",
      userGroup: "Grupo de usuários",
      admin: "Admin",
      hasPermissionManageInviteUsers:
        "Tem permissão para gerenciar e convidar novos usuários.",
      basic: "Básico",
      canAccessEditAllFeatures:
        "Pode acessar e editar todos os recursos do i4cast.",
      pleaseInput: "Insira",
      granted: "Permitido",
      denied: "Negado",
      name: "Nome",
      username: "Usuário",
      email: "Email",
      permissionGroup: "Grupo de permissão",
      accessToI4cast: "Acesso ao i4cast®",
      areYouSureWantDeactivateUser:
        "Tem certeza que deseja desativar este usuário?",
      areYouSureWantreactivateUser:
        "Tem certeza que deseja reativar este usuário?",
      yes: "Sim",
      no: "Não",
      deactivate: "Desativar",
      reactivate: "Reativar",
      ok: "Ok",
      reset: "Resetar",
      invalidUserGroup: "Grupo de usuários inválido",
      expiredInvitation: "Convite expirado",
      invitedAlreadyUsed: "Convite já utilizado",
      thisEmailAlreadyInvited: "Este e-mail já foi convidado",
      thisEmailAlreadyUse: "Esse email já está em uso",
      invitationNotFound: "Convite não encontrado",
      readOnly: "Somente leitura",
      allowVisualizeData:
        "Permite visualizar dados e informações sem a capacidade de alterá-los.",
    },
  },
  analytics: {
    waterLevel: "Nível d'água",
    weather: "Tempo",
    current: "Corrente",
    wind: "Vento",
    wave: "Onda",
    selectRegion: "Selecione uma região",
    selectStation: "Selecione uma estação",
    sensorDataNotAvailable:
      "Desculpe, o dado do sensor não esta disponível para essa estação.",
    errorGetSensorData:
      "Erro ao buscar dados do sensor. Por favor entre em contato.",
    sensorData: "Dados do sensor",
    errorToTryGetDataStationOnAnalytics:
      "Erro ao tentar obter a estação de dados no tipo de gráfico analítico medido. Entre em contato conosco.",
    sorryButNoSensorsAreAvailableforData:
      "Desculpe, mas não há sensores disponíveis para este tipo de dados.",
  },
  atmOcean: {
    clickToGoBackPastForecasts: "Clique para voltar às previsões anteriores",
    youNeedSelectStationToExport:
      "Ops! Você precisa selecionar uma estação para exportar os dados.",
    waterFlow: "Nível e Correntes",
    waveSea: "mar de ondas",
    waveSwell: "ondulação",
    selectStation: "Selecione uma estação",
    exportCsv: "Exportar .csv",
    time: "Tempo",
  },
  dynamicUKC: {
    dynamicClearanceNotAvailable:
      "Cálculos dinâmicos de folga abaixo da quilha não estão disponíveis para sua empresa. Gostaria de solicitar esta funcionalidade?",
    gotIt: "Entendi!",
    skip: "pular",
    settings: "Configurações",
    ukcRestriction: "Restrição de FAQ",
    meters: "metros",
    close: "Fechar",
    save: "Salvar",
    station: "Estação",
    travelCode: "Código de viagem",
    dynamicUkcDocking: "FAQ Dinâmica - Atracação",
    maneuverDraft: "Calado de Manobra",
    dynamicUkc: "FAQ Dinâmica",
    ukcRestrictionLower: "Restrição FAQ",
    dynamicUkcUndocking: " FAQ Dinâmica - Desatracação",
    selectShip: "Selecione um navio",
    alert: "Alerta",
    clickToBeRedirected:
      "Clique abaixo para ser redirecionado para a FAQ Dinâmica",
    gotToDynamicUkc: "Ir para FAQ Dinâmica",
  },
  forecastChart: {
    forecastChartFormStation: {
      informStationDescription: "Informe a descrição da estação.",
      failedGetVirtualStationGeolocation:
        "Falha ao obter a geolocalização da estação virtual. Entre em contato conosco.",
      youAreOutOfRegionArea: "Ops! Você está fora da área da região.",
      stationDescription: "Descrição da estação",
      save: "Salvar",
      doYouWantSaveStation: "Deseja salvar a estação?",
      saveStation: "Salvar estação",
      areYouSureWantDeleteStation:
        "Tem certeza de que deseja excluir esta estação?",
      yes: "Sim",
      no: "Não",
      requiredField: "Campo obrigatório",
      invalidLatLng: "Latitude e longitude inválidas",
      searchCoordsLatLng: "Pesquisar coordenadas (latitude e longitude)",
      cancel: "Cancelar",
      search: "Pesquisar",
      searchCoord: "Pesquisar coordenadas",
      removeStation: "Remover estação",
      unauthorizedUser:
        "Ops! Parece que você não tem permissão para deletar esta estação. Entre em contato com o administrador do sistema.",
      youOnlyHaveReadPermission: "Você só tem permissão para leitura",
    },
    forecastChartPage: {
      youAreOutOfRegionArea: "Ops! Você está fora da área de sua região.",
      thereIsAlreadyStationName: "Já existe uma estação com esse nome:",
      errorToSaveVirtualStation:
        "Erro ao salvar estação virtual. Por favor, entre em contato conosco.",
      successSationHasSaved: "Sucesso! A estação foi salva",
      errorToRemoveStation:
        "Erro ao remover a estação. Por favor, entre em contato conosco.",
      station: "Estação:",
      errorToGetDataStationMeteogramChartType:
        "Erro ao tentar obter a estação de dados no tipo de gráfico de meteograma. Por favor, entre em contato conosco.",
      errorToGetDataValidStationMeteogramChartType:
        "Erro ao tentar obter uma estação válida de dados no tipo de gráfico de meteograma. Por favor, entre em contato conosco.",
      stationWithInsight: "Estação com Insight",
      clickBlueMarkersInTheMap:
        "Clique nos marcadores azuis no mapa caso queira",
      toDisplayForecastChartWaveSpectra:
        " exibir gráficos de previsão com tipo de dados Wave Spectra ",
      letsGo: "Vamos lá",
      newEraForForecast: "Uma nova era para a previsão",
      geospatialInsight: "Insight geoespacial",
      exploreGeospatialInsights:
        "Explore insights geoespaciais para compreender as condições climáticas em sua região. Tome decisões com uma visão completa das condições meteorológicas em áreas estratégicas para otimizar suas operações e planejamento.",
      minimizeRisks: "Minímize riscos",
      analysingPoints: "análisando pontos ao seu redor",
      customAnalysis:
        "Personalização das Análises: Personalize suas análises climáticas de acordo com os Insights selecionados",
      newEraGeospatial: "Uma nova era geoespacial",
      inI4castPush: "no i4cast impulsiona a tomada de decisões estratégicas.",
      insightForAllStations: "Insight para todas as estações",
      chooseInsight:
        "Escolha um insight e navegue entre as estações para visualizá-lo.",
      insightType: "Tipos de Insight",
      nowToCreateAnInsight:
        "Agora, ao criar um Insight, é possível categorizá-lo como 'Permissivo' ou 'Restritivo'. Isso significa que, ao selecionar um Insight na tela de Prevísão, ele será representado de acordo com a categoria escolhida da seguinte maneira:",
      permissive: "Permissivo",
      noEvent: "Sem evento",
      warning: "Atenção",
      withEvent: "Com evento",
      restrictive: "Restritivo",
      gotIt: "Entendido",
      back: "Voltar",
      next: "Próximo",
      skip: "Pular",
      last: "Fechar",
      weWillGuideYouThrough:
        "Vamos te guiar pelo Forecast Chart para ampliar a sua gestão de risco climático.",
      clickToAccessYourList:
        "Acessa a sua lista de insights ou digite o nome para otimizar a busca. Ao selecionar o insight, você visualizará a restrição ou permissão no mapa.",
      youAreNowAble:
        "Agora você pode analisar diferentes condições ambientais no mesmo gráfico. Clique na condição ambiental desejada para ativá-la/desativá-la.",
      youCanViewTwoForecast:
        "Você pode visualizar dois modos de previsão no gráfico. Modo Previsão: A melhor previsão hiper-local é exibida. É ideal para análise de um cenário específico.",
      probabilityMode:
        "Modo probabilidade [Novidade✨]: Permite explorar a probabilidade de ocorrência dos cenários futuros. É ideal para analisar diferentes cenários e planejar dinamicamente.",
      sensorMode:
        "Modo sensor [Novidade✨]: Permite explorar seus sensores. É ideal para analisar seus dados.",
      theInneCircleDisplays:
        "O círculo interno exibe a condição prevista do insight para o período atual. O círculo externo exibe a condição prevista do insight para o período futuro da time line.",
      dragTheTimeline:
        "Arraste a time line para visualizar a previsão do insight ao longo do tempo.",
      whenSlectingAnInsight:
        "Ao selecionar um Insight, os ícones das estações próximas dentro de um raio de 5km serão trocados por círculos coloridos que representam as condições do Insight, seguindo a legenda para facilitar a interpretação.",
      forEachSelectedEnvironmental:
        "Para cada condição ambiental selecionada a melhor previsão (linha preta) é exibida em conjunto com a probabilidade de 50% e 80% de ocorrência, e mediana (linha amarela tracejada). Quanto mais próximo a mediana e menor o espalhamento das probabilidades, mais confiável será o cenário futuro.",
      ifTheViewedRegion:
        "Se a região visualizada não possuir dados de probabilidade, por favor, entre em contato conosco.",
      sensorForecastData:
        "Ops! Não há sensores aqui. Clique em um local com ícones de sensores para ver os dados.",
      noSensorWasFound:
        "Nenhum sensor foi encontrado para a variável selecionada",
      stationsWithSensor:
        "✨Novidade: As estações com dados de sensores serão destacadas no mapa.",
      theMostRecentMeasuredData:
        "O dado medido mais recente será exibido imediatamente no mapa.",
      toIdentifyTheMeasured:
        "Para identificar a variável medida, passe o mouse sobre o sensor para ver mais detalhes.",
      clickOnTheSensor:
        "Clique no sensor para visualizar os dados medidos no gráfico.",
      nowYouCanAccess:
        "Agora você pode acessar o relatório de previsão MetOcean direto do i4cast",
      justClickOnTheHighlighted: "Basta clicar no botão em destaque",
      selectVariablesAndStations:
        "Selecione as variáveis e estações que deseja visualizar.",
      yourReportWillBeReady:
        "Seu relatório ficará pronto em alguns segundos. 📄",
      lastWarning: "Um último aviso 💡: ",
      youWillBeRedirected:
        "você será direcionado para outra aba, então certifique-se de que o seu navegador não bloqueou o pop-up.",
      toGenerateMetoceanReport:
        "Para gerar o relatório de previsão MetOcean, clique aqui.",
    },
    forecastChartPanelHeader: {
      noData: "Insight não encontrado!",
      enterAtLeastThreeLetters: "Insira pelo menos 3 letras",
      enterYourInsight: "Digite o seu Insight aqui",
      showInsightInForecastCharts: "Exibir insight no gráfico de previsão",
      insightWithoutData: "Estações sem dados",
      theGrayIconIndicates:
        "O ícone cinza indica falta de dados para o Insight selecionado",
      thereIsNoInsightDataAvailable:
        "Não há dados de insight disponíveis para essa estação",
      noEventsForThisStation: "Não há nenhum evento previsto para essa estação",
      markAsFavorite: "Marcar como favorito para acesso rápido!",
      insightFavoriteSuccessOnSave:
        "Sucesso! Seu Insight foi marcado como favorito",
      opsErrorMarkingInsightAsFavorite:
        "Ops! Erro ao marcar insight como favorito",
      insightFavoriteSuccessOnDelete:
        "Sucesso! Seu Insight foi removido como favorito",
      opsErrorOnRemoveInsightAsFavorite:
        "Ops! Erro ao remover insight como favorito",
      removeFavorite: "Remover favorito",
      selectStation: "Oops! Selecione uma estação para visualizar o insight!",
      selectInsightToEnable:
        "Selecione um insight para ativar esta visualização.",
    },
    forecastChartInsightTimeline: {
      swapToSetRanges: "Deslize para definir intervalos nos círculos externos.",
      useTheCursorToAdjustTime:
        "Use o cursor para ajustar os intervalos de tempo no círculo externo. Arraste para definir novos períodos.",
    },
    forecastChartInsightLegend: {
      realTimeForecast: "Previsão tempo real",
      forecastTimeline: "Período da Timeline",
      innerCircle: "Círculo interno",
      outerCircle: "Círculo externo",
    },
    forecastChartPanelEnvironmentalButtons: {
      weather: "Tempo",
      wave: "Ondas",
      waveHeight: "Altura da onda",
      waveHeightMax: "Altura máxima da onda",
      wavePeakPeriod: "Período de pico",
      waveMeanPeriod: "Período médio",
      waterFlow: "Nível e Correntes",
      stationWithInsight: "Estação com Insight",
      wind: "Vento",
      wind10m: "Vento 10m",
      wind50m: "Vento 50m",
      wind100m: "Vento 100m",
      gust10m: "Rajada 10m",
      gust50m: "Rajada 50m",
      gust100m: "Rajada 100m",
      temperature: "Temperatura",
      waveTotal: "Total",
      waveSea: "Local",
      waveSwell: "Swell",
      rain: "Chuva",
    },
    forecastChartPanelMap: {
      searchLocationOrLatLon: "local de pesquisa ou lat, lon",
      exactLocation: "Localização exata",
      insightDataUpdate: "Marcadores geoespaciais resetados.",
      insightDataUpdateDescription:
        "Último cálculo de insights há 15 minutos para esta região. Dados atualizados na área selecionada.",
      searchAddress: "Pesquisar endereço",
      noInsightData: "sem dados de Insight",
      sensorDataIsDelayed: "Os dados do sensor estão atrasados.",
      loading: "Carregando",
      noRadarDataAvailable: "Sem dados de radar disponível.",
    },
    forecastChartPanelMeteogram: {
      all: "max",
      dataInterval: "Intervalo de dados",
      refreshToseeNewData: "Atualize para ver os novos dados disponíveis",
      thereIsnotDataAvailable:
        "Ops! Não há dados de {{empty}} {{location}} {{empty2}} disponíveis neste local.",
      thereIsnotProbabilisticDataAvailable:
        "Ops! Não há dados probabilísticos de {{empty}} {{location}} {{empty2}} disponíveis neste local.",
      thereIsnotSensorDataAvailable:
        "Ops! Não há dados medidos de {{empty}} {{location}} {{empty2}} disponíveis neste local.",
      somethingWrong: "Ops! Algo deu errado!",
      lastUpdatedAt: "Última atualização em",
      delayedSensor: "Os dados do sensor estão atrasados",
      nextUpdateEstimatedAt: "Próxima atualização estimada em",
      aboutForecast: "Sobre a previsão",
      aboutSensor: "Sobre os sensores",
      aboutProbabilistic: "Sobre as probabilidades",
      hours: " horas",
      hour: " hora",
      min: "min",
      share: "Compartilhar previsão",
      newsOnForecast: "Novidade nas previsões",
      nowItsPossibleShare:
        "Agora é possível compartilhar as previsões com outras pessoas de forma fácil e rápida! Clicando em",
      onForecastScreen:
        "na tela de Previsões, você pode enviar suas previsões por e-mail.",
      close: "Fechar",
      gotIt: "Entendido",
      insights: "Insights",
      select: "Selecionar",
      selectInsightFromList: "Selecione um Insight para ser exibido no gráfico",
      compareInsightsWithMeteogram: "Compare insights junto ao meteograma!",
      selectInsight: "Selecionar Insight",
      showInsight: "Exibir Insight",
      noEventsForThisStation: "Não há nenhum evento previsto para essa estação",
      toViewTheInsightSelectALongerPeriod:
        "Nas próximas {{hours}}h não terá evento.Para visualizar o Insight selecione um período maior",
      nowYouCanVisualizeTheInsightOnMeteogram:
        "Agora você pode visualizar o seu Insight junto com o Meteograma! Uma estação com Insight está representada no mapa em verde, basta seleciona-lá, escolher o Insight que deseja visualizar e pronto! Seu Insight irá aparecer no gráfico",
      noEvent: "sem evento",
      warning: "atenção",
      withEvent: "com evento",
      insightOff: "Insight inativo",
      insightCloseToBeingActivated: "Insight próximo de ser ativado",
      insightOn: "Insight ativo",
      clickHereToOpenTheGuide: "Clique parar saber mais sobre a previsão",
      confidence50: "50% de confiança",
      confidence80: "80% de confiança",
      median: "Mediana",
      windSpeed10m: "Velocidade do vento 10m",
      windSpeed50m: "Velocidade do vento 50m",
      significantWaveHeight: "Altura significativa da onda",
      closeChart: "Fechar gráfico",
      unavailable: "Indisponível",
      deterministic: "Previsão",
      probabilistic: "Probabilidade",
      windsGusts: "Ventos/Rajadas",
      preciptation: "Chuva",
      waveHeights: "Alturas de ondas",
      peakPeriod: "Período de pico",
      wavePower: "Potência de onda",
      clickOnMapToOpen: "Clique no mapa para abrir novamente o gráfico.",
      itAppearsThatYourRegion:
        "Essa região não possui dados de probabilidade para a variável selecionada",
      contactOurTeamToEnsure:
        "Entre em contato conosco para trazer essa funcionalidade para você!✨",
      thisRegionDoesNotHavePrecip:
        "Essa região não possui dados sobre a precipitação mínima, máxima e a probabilidade de chuva.",
      getInTouch: "Entre em contato",
      forecastChart: "Gráfico de previsão",
      probabilisticChart: "Gráfico de probabilidade",
      sensorChart: "Gráfico de dados medidos",
      showTutorial: "Mostrar tutorial",
      watchTutorial: "Assista ao tutorial",
      showTutorialsListButton: "Ver lista de outros tutoriais",
      hideTutorialsListButton: "Esconder tutorias extras",
      forecastTutorialLabel: "Mostrar tutorial de Previsão",
      geospatialTutorialLabel: "Mostrar tutorial de Geoespacial",
      probabilisticTutorialLabel: "Mostrar tutorial de Probabilidade",
      initialSensorTutorialLabel: "Mostrar tutorial de Sensor inicial",
      sensorTutorialLabel: "Mostrar tutorial de Sensor",
      selectStationAndInsight: "Selecione uma estação e um insight",
      selectStation: "Selecione uma estação",
      metOceanForecastReport: "Relatório de previsão MetOcean",
      metOceanReportTutorialLabel: "Mostrar tutorial Relatório metocean",
      probability: "Probabilidade",
      reflectivity: "Refletividade (dBZ)",
      vweak: "M.FRACO",
      weak: "FRACO",
      moderate: "MODERADO",
      strong: "FORTE",
      vstrong: "M.FORTE",
      precipitationPotential: "Potencial de Precipitação (mm/h)",
      radarDataUnavailable:
        "Dados de radar indisponíveis. Por favor, tente novamente mais tarde.",
    },
    forecastChartPanelMeteogramEnvironmentalButtons: {
      wave: "Onda total",
      waveSea: "Onda local",
      waveSwell: "Onda swell",
    },
    forecastChartPanelMeteogramWeatherButtons: {
      weather: "Vento",
      weatherTemperature: "Temperatura",
    },
    forecastChartPastForecast: {
      noPastDataAvailable: "Não há dados anteriores disponíveis",
      pastForecast: "Previsões anteriores",
      pastMeasurement: "Medições anteriores",
      time: "Horário:",
    },
    forecastChartRequestSpectraStationButton: {
      yourRequestSuccessfullySent: "Sua solicitação foi enviada com sucesso!",
      requestSeaSwellAtThisLocation:
        "Solicite ondas de mar e swell neste local",
    },
    forecastChartMeteogramStore: {
      errorTryGetDataChartMeteogram:
        "Erro ao buscar dados do meteograma. Por favor entre em contato.",
      noUpcomingEvents: "NENHUM EVENTO PRÓXIMO",
    },
    forecastChartProbabilisticStore: {
      errorTryGetData: "Erro ao buscar dados. Por favor entre em contato.",
    },
    metOceanReport: {
      metOceanForecastReport: "Relatório de previsão MetOcean",
      generate: "Gerar",
      selectAtLeastOneVariable:
        "Selecione pelo menos uma variável e uma estação",
      selectAtLeastOneStation:
        "Selecione uma estação para a variável selecionada",
      selectTheVariablesAndStations:
        "Selecione as variáveis e estações que deseja visualizar",
      weather: "Tempo",
      wave: "Onda",
      waterFlow: "Nível e Correntes",
      cancel: "Cancelar",
      generatingForecast: "Gerando relatório de previsão...",
      pleaseWaitAMoment:
        "Aguarde um momento, isso pode levar alguns segundos! ⏳",
      weWereUnableToOpen:
        "Não conseguimos abrir o PDF porque o navegador bloqueou pop-ups. Habilite pop-ups nas configurações do seu navegador e tente novamente.",
    },
  },
  portOfCallStore: {
    errorToGetOperationWindowData:
      "Erro ao obter os dados da janela de operação. Por favor, entre em contato conosco.",
    errorToGetDockingDynamicUkcData:
      "Erro ao obter dados UKC dinâmicos de ancoragem. Por favor, entre em contato conosco.",
  },
  unitMeasurementStore: {
    errorToAddUnitMeasurement:
      "Erro ao adicionar unidade de medida. Por favor, entre em contato conosco",
  },
  whatIsNew: {
    whatIsNew: "O que há de novo!",
    markAllAsRead: "Marcar tudo como lido.",
  },
  share: {
    shareForecast: "Compartilhar previsão",
    period: "Período",
    all: "max",
    environmentalVariables: "Variáveis ​​ambientais",
    weather: "Tempo",
    selectStation: "Selecione a estação",
    wave: "Onda",
    waterFlow: "Nível e Correntes",
    recipients: "Destinatários",
    sendMeThisEmail: "Envie-me este e-mail",
    externalRecipients: "Destinatários externos",
    inviteToI4cast: "Convidar para i4cast",
    message: "Mensagem (opcional)",
    cancel: "Cancelar",
    submt: "Enviar",
    inThisFieldItsPossibleShareByEmail:
      "Compartilhe por e-mail com pessoas que ainda não fazem parte do i4cast.",
    separateEmailsWithComma:
      "Separe os e-mails com vírgula. Ex: john@i4sea.com, new@i4sea.com",
    selectAtLeastOneEnvironmentalVariable:
      "Selecione pelo menos uma variável ambiental e uma estação.",
    emailsBelowAreNotValid: "Os emails abaixo não são válidos.",
    selectAtLeastOneVariable: "Selecione pelo menos uma variável e uma estação",
    youMustSelectAtLeast:
      "É preciso selecionar pelo menos um destinatário interno ou externo",
    selectAtLeastOneStation:
      "Selecione uma estação para a variável selecionada",
    successEmailHasSend: "Sucesso! Seu email foi enviado",
    errorSendingEmail: "Erro ao enviar o email",
    helloIWouldLikeToShare:
      "Olá, gostaria de compartilhar com você os dados de previsão do i4cast. Anexo a este e-mail.",
    thereIsnotDataInThisStation:
      "Não há dados do tipo {{environmentalTypeTranslated}} na estação: {{stationName}}. Por favor, selecione outra estação",
    water_flow: "Nível e Correntes",
    shareReportByEmail:
      "Compartilhe um relatório da previsão via email sem sair do i4cast!",
    forGuestUsersMustUseAnEmailDomain:
      "Convide novos usuários para o i4cast. Apenas usuários com o domínio da sua empresa poderão ser convidados (Ex.: joao@i4sea.com). Caso precise convidar outros usuários, entre em contato conosco.",
  },
  dualList: {
    selectAll: "Selecionar todos",
    notSelected: "Não selecionados",
    deselectAll: "Desmarcar todos",
    selected: "Selecionados",
    search: "Pesquisar",
  },
  occurrence: {
    reportOccurrence: "Reportar ocorrência",
    reportOccurrenceDetail:
      "Reporte uma ocorrência que gerou impacto nas suas operações. A partir disso, nosso sistema poderá identificar e notificar previsões semelhantes no futuro.",
    cancel: "Cancelar",
    save: "Salvar",
    selectStation: "Selecione a estação mais próxima do local da ocorrência",
    selectEnvironmentalVariables:
      "Selecione as variáveis ambientais que podem ter impactado nesta ocorrência",
    ocurrencePeriod: "Período da ocorrência",
    singleDate: "Data única",
    period: "Início e fim",
    description: "Descrição da ocorrência",
    strongWinds: "Ventos fortes interromperam a operação de conteiner",
    latitude: "Latitude",
    longitude: "Longitude",
    forecastHelp: "A previsão do i4cast acertou?",
    wantToBeNotified:
      "Desejo ser avisado quando condições semelhantes forem previstas",
    yes: "Sim",
    no: "Não",
    wind: "Vento",
    current: "Corrente",
    wave: "Onda",
    rain: "Chuva",
    fog: "Neblina",
    waterLevel: "Nível d'água",
    temperature: "Temperatura",
    occurrences: "Ocorrências",
    newOccurrence: "Reportar ocorrência",
    search: "Pesquisar",
    descriptionRequired: "Descrição é obrigatória!",
    stationRequired: "Selecione uma estação!",
    latitudeRequired: "Latitude é obrigatória!",
    longitudeRequired: "Longitude é obrigatória!",
    selectAtleastOneVariable:
      "Selecione ao menos uma variável ambiental que pode ter impactado na ocorrência.",
    successReport: "Ocorrência reportada com sucesso!",
    errorReport: "Erro ao reportar ocorrência!",
    deleteOccurrence: "Excluir Ocorrência",
    delete: "Excluir",
    areYouSureWantDelete:
      "Você tem certeza que deseja excluir essa Ocorrência?",
    errorEditOccurrence: "Erro ao editar ocorrência!",
    successEditOccurrence: "Sucesso! Sua ocorrência foi salva",
    errorYouNeedToSelectAnOccurrence:
      "Erro! Você precisa selecionar uma ocorrência",
    youDonyHaveAOccurrenceYet:
      "Você ainda não possui uma ocorrência. Clique no botão para reportar uma ocorrência",
    addOccurrence: "Reportar Ocorrência",
    time: "Hora:",
    selectDate: "Selecione uma data",
    dateRequired: "Data é obrigatório!",
    exactLocation:
      "Deseja informar a localização exata da ocorrência? (opcional)",
    selectOption: "Selecione uma opção!",
    startDateMustBeLessThanEndDate:
      "A data inicial deve ser menor do que a data final",
    windInfo:
      "Ao selecionar esta opção, o i4cast utilizará dados da velocidade do vento, direção e rajada para identificar eventos semelhantes.",
    waveInfo:
      "Ao selecionar esta opção, o i4cast utilizará dados da altura significativa das ondas, direção e período de pico para identificar eventos semelhantes.",
    rainInfo:
      "Ao selecionar esta opção, o i4cast utilizará dados de precipitação para identificar eventos semelhantes.",
    fogInfo:
      "Ao selecionar esta opção, o i4cast utilizará dados de visibilidade para identificar eventos semelhantes.",
    temperatureInfo:
      "Ao selecionar esta opção, o i4cast utilizará dados de temperatura do ar para identificar eventos semelhantes.",
    waterLevelInfo:
      "Ao selecionar esta opção, o i4cast utilizará dados de nível d'água para identificar eventos semelhantes.",
    currentInfo:
      "Ao selecionar esta opção, o i4cast utilizará dados de velocidade e direção das correntes para identificar eventos semelhantes.",
    forecastCorrect:
      "Na sua opinião, a previsão do i4cast te ajudou a identificar essa ocorrência?",
    occurrencesAreEventsThatImpactYourOperation:
      "Ocorrências são eventos que podem causar impacto nas suas operações. Reporte ocorrências para que o i4cast se torne mais inteligente. Identificaremos previsões similares no futuro e você será notificado.",
    startHourIsRequired: "A hora de início é obrigatória",
    endHourIsRequired: "A hora final é obrigatória",
    importOccurrence: "Importar ocorrência",
    selectFileToImport:
      "Selecione um arquivo para importar (pdf, excel, word e txt):",
    import: "Importar",
    successOnImport: "Sucesso! Recebemos seu arquivo com as ocorrências.",
    youNeedToChooseFile: "Selecione um arquivo para importar!",
    weAreProcessingTheWorkSheet:
      "Estamos processando a planilha com as ocorrências. Em breve, iremos notificar você assim que estiverem concluídas.",
    theSelectedFileExceedsMaxium:
      "O arquivo selecionado excede o tamanho máximo permitido de 5MB.",
    importYourOccurrencesSpreadSheet:
      "Já tem algum documento com registro de ocorrências climáticas? Importe aqui e nós cadastramos pra você!",
    successfullyUploaded: "Upload feito com Sucesso!",
    yourFileWithOccurrenceWasSent:
      "Seu arquivo com ocorrências foi enviado com sucesso e assim que estiverem cadastradas no i4cast, você será notificado por e-mail!",
    iGotIt: "Entendi!",
    selectAFile: "Selecionar arquivo",
    selectedFile: "Arquivo selecionado:",
    noFileSelected: "Nenhum arquivo selecionado",
    newsInOccurrence: "Novidade nas ocorrências",
    nowItsPossibleImportSpreadSheet:
      "Agora é possível importar uma planilha ou documento com as suas ocorrências, facilitando o cadastro e tornando o i4cast mais inteligente!",
    try: "Experimentar",
  },
  feedback: {
    successSendFeedback: "Sucesso! Seu feedback foi enviado",
    errorSendFeedback: "Erro ao enviar feedback",
    thisFieldIsRequired: "Esse campo é obrigatório",
    send: "Enviar",
    yes: "Sim",
    no: "Não",
    typeYourAnswerHere: "Digite aqui a sua resposta",
  },
  manageStation: {
    manageStation: "Gestão de estações",
    configureWhichStationViewHistoricalData:
      "Configure quais estações você deseja visualizar dados históricos",
    name: "Nome",
    region: "Região",
    latLon: "Localização (lat, lon)",
    historicalData: "Dados históricos",
    search: "Pesquisar",
    station: "Estação",
    page: "Página",
    of: "de",
    filterBy: "Filtrar por",
    forecastDataType: "Tipo de dados de previsão",
    weather: "Tempo",
    wave: "Onda",
    waveSpectra: "Onda Espectral",
    waveSea: "Onda local",
    waveSwell: "Onda swell",
    waterFlow: "Nível e Correntes",
    dataHistory: "Histórico de dados",
    environmentalType: "Tipo ambiental",
    selectAtLeastOneEnvironmentalType:
      "Selecione pelo menos um tipo de variável ambiental",
    save: "Salvar",
    successEditStation: "Sucesso! Sua estação foi salva",
    errorEditStation: "Erro ao editar estação!",
    errorNoEnvironmentalDataAtStation:
      "Existem tipos de variáveis ambientais não disponíveis para essa estação!",
    enterAtLeastThreeLetters: "Digite ao menos 3 letras",
    notFound: "Não encontrado",
    clean: "Limpar",
  },
  analyticsReporting: {
    period: "Período de análise",
    environmentalType: "Tipo de evento",
    weather: "Tempo",
    location: "Localização",
    environmentalVariable: "Variável ambiental",
    windSpeed: "Velocidade do vento",
    direction: "Direção",
    minimum: "Mínimo",
    maximum: "Máximo",
    knots: "nós",
    forecastAdvancePeriod: "Período de cada previsão",
    before: "antes",
    generateAnalysis: "Gerar análise",
    hoursEarned: "Horas ganhas",
    hoursGainIsHowMuchMoreAccuratei4castWas:
      "Horas ganhas representa a quantidade de horas que o i4cast foi mais assertivo em comparação ao modelo global",
    moreThanGlobal: "mais assertivo que o modelo global",
    eventsCaptured: "Eventos capturados",
    fillINTheFieldsBelowViewData:
      "Preencha os campos ao lado para visualizar os dados",
    eventsCapturedRepresentTheQuantityOfEvents:
      "Eventos capturados representa a quantidade de eventos que o i4cast capturou a mais do que o modelo global",
    degrees: "graus",
    region: "Região",
    selectRegion: "Selecione uma região",
    selectLocation: "Selecione um localização",
    selectEvent: "Selecione um evento",
    isGreaterOrEqual: "Maior ou igual",
    isLessOrEqual: "Menor ou igual",
    startDate: "Data inicial",
    endDate: "Data final",
    requiredField: "Campo obrigatório",
    isGreaterOrEqualThan: "Maior ou igual a",
    isLessOrEqualThan: "Menor ou igual a",
    between: "entre",
    to: "a",
    globalModel: "Modelo Global",
    sensor: "Observado",
    numberOfEvents: "Qtd. de eventos",
    events: "Eventos",
    comparisonOfi4castForecastPerformance:
      "Comparação da performance da previsão do i4cast com a previsão global.",
    fillAtLeastOneField: "Preencha ao menos um dos campos",
    generatingAnalysis: "gerando análise...",
    moreAssertiveForecastHours:
      "de previsão mais assertiva do que o modelo global",
    weAreGeneratingAnalysis:
      "Estamos gerando a sua análise, isso poderá demorar alguns segundos.",
    weDoNotHaveDataAvailable:
      "Não temos dados disponíveis para o filtro selecionado. Insira novas informações para tentar novamente.",
    timeCalculation: "Cálculo de tempo",
    timeCalculationIsAPercentageOfTime:
      "Cálculo de tempo representa a porcentagem de tempo em que a condição filtrada esteve ativa na sua região",
    ofTheTimeWithWinds: "do tempo com ventos de",
    january: "Janeiro",
    february: "Fevereiro",
    march: "Março",
    april: "Abril",
    may: "Maio",
    june: "Junho",
    july: "Julho",
    august: "Agosto",
    september: "Setembro",
    october: "Outubro",
    november: "Novembro",
    december: "Dezembro",
    mostFrequently: "Mais frequente em",
    errorGeneratingAnalytics: "Erro ao gerar análise",
    theMaximumValueMustBeGreater:
      "O valor máximo deve ser maior ou igual do que o valor mínimo",
    ofTheTimeWithWindsGreaterOrEqual: "do período com vento maior ou igual",
    ofTheTimeWithWindsLessOrEqual: "do período com vento menor ou igual",
    someMonthsMayNotBeDisplayed:
      "Alguns meses podem não ser exibidos no gráfico devido à insuficiência de dados para análise de desempenho.",
    monthlyAverageWindSpeed: "Média mensal da velocidade de ventos",
    windDirection: "Direção do vento",
    average: "Média",
    standardDeviation: "Desvio padrão",
    eventFrequency: "Frequência do evento",
    first: "Primeiras",
  },
  eventHistory: {
    selectStationAndDefinePeriod:
      "Selecione uma estação e data inicial para visualizar uma análise comparativa da previsão no período de 5 dias.",
    eventHistory: "Histórico de eventos",
    selectStation: "Selecione uma estação",
    startDate: "Data inicial",
    endDate: "Data final",
    weather: "Tempo",
    wave: "Onda",
    waterFlow: "Nível e Correntes",
    waterLevel: "Nível d'água",
    current: "Corrente",
    sensor: "Sensor",
    global: "Modelo global",
    noGlobalModelDataFound:
      "Nenhum dado do modelo global foi encontrado para o filtro selecionado",
    noSensorDataFound:
      "Nenhum dado do sensor foi encontrado para o filtro selecionado",
    noi4castDataFound:
      "Nenhum dado do i4cast foi encontrado para o filtro selecionado",
    selectStationToViewData: "Selecione uma estação para visualizar os dados",
    environmentalVariable: "Variável ambiental",
  },
  eventAnalysis: {
    eventAnalysis: "Análise de eventos",
    occurrence: "Ocorrência",
    period: "Período",
    location: "Localização",
    weather: "Tempo",
    wave: "Onda",
    waterFlow: "Nível e Correntes",
    wind: "Vento",
    gust: "Rajada",
    rain: "Chuva",
    minimumValue: "Mínimo de",
    averageValue: "Média de",
    maxValue: "Máxima de",
    accumulatedValue: "Acúmulo",
    peakValue: "Pico às",
    averageWindGust: "Média de Vento | Rajada",
    maxWindGust: "Máximo de Vento | Rajada",
    averageWavePeriod: "Média de Altura | Período",
    maxWavePeriod: "Máximo de Altura | Período",
    minWaterFlowCurrent: "Mínimo da Corrente",
    maxWaterFlowCurrent: "Máximo da Corrente",
    timeSeries: "Série temporal",
    theChartBelowShows:
      "O gráfico abaixo representa uma série temporal dos dados brutos do período da ocorrência.",
    significantWaveHeight: "Altura significativa da onda",
    wavePeakPeriod: "Período de pico da onda",
    waterLevel: "Nível d’água",
    current: "Corrente",
    selectOccurrence: "Selecione uma ocorrência",
    selectAnOccurrenceToBegin:
      "Selecione uma ocorrência para começar a sua análise",
    knots: "nós",
    latitude: "latitude",
    longitude: "longitude",
    noi4castDataFound:
      "Nenhum dado do i4cast foi encontrado para a ocorrência selecionada",
  },
  environmentalVariableStore: {
    windSpeed: "Vento - Velocidade a 10m",
    windDirection: "Vento - Direção a 10m",
    windGusts: "Vento - Rajada a 10m",
    waterLevel: "Nível d'água",
    currentVelocity: "Corrente - Velocidade",
    currentDirection: "Corrente - Direção",
    waveHeight: "Onda - Altura Significativa",
    waveDirection: "Onda - Direção do Pico",
    peakPeriod: "Onda - Período de Pico",
    meanPeriod: "Onda - Período Médio",
    seaWaveHeight: "Onda - Altura Significativa local",
    seaWaveDirection: "Onda - Direção do Pico local",
    seaPeakPeriod: "Onda - Período de Pico local",
    swellWaveHeight: "Onda - Altura Significativa de Swell",
    swellWaveDirection: "Onda - Direção do Pico de Swell",
    swellPeakPeriod: "Onda - Período de Pico de Swell",
    precipitation: "Precipitação",
    windSpeedAt80m: "Vento - Velocidade a 50m",
    windDirectionAt80m: "Vento - Direção a 50m",
    windGustsAt80m: "Vento - Rajada a 50m",
    windSpeedAt100m: "Vento - Velocidade a 100m",
    windDirectionAt100m: "Vento - Direção a 100m",
    windGustsAt100m: "Vento - Rajada a 100m",
    maximumWaveHeight: "Onda - Altura Máxima",
    visibility: "Visibilidade",
    airTemperature: "Temperatura do Ar",
    dewPoint: "Ponto de Orvalho",
  },
  environmentalVariableEnum: {
    weather: "Tempo",
    weather_temperature: "Temperatura",
    wave: "Ondas",
    wave_sea: "Onda de vento",
    wave_swell: "Onda de swell",
    water_flow: "Nível e Correntes",
    wind_vel: "Vento 10m",
    wind_dir: "Direção 10m",
    gust: "Rajada 10m",
    water_level: "Nível d'água",
    current_vel: "Corrente vel",
    current_dir: "Corrente dir",
    wave_height: "Onda - altura significativa",
    wave_dir: "Onda - direção de pico",
    peak_period: "Onda - período de pico",
    mean_period: "Onda - período médio",
    wave_height_sea: "Onda de vento - altura",
    wave_dir_sea: "Onda de vento - direção período de pico",
    peak_period_sea: "Onda de vento - período de pico",
    wave_height_swell: "Onda swell - altura",
    wave_dir_swell: "Onda swell - direção de pico",
    peak_period_swell: "Onda swell - período de pico",
    precip: "Chuva",
    wind_vel_80m: "Vento 50m",
    wind_dir_80m: "Direção 50m",
    gust_80m: "Rajada 50m",
    wind_vel_100m: "Vento 100m",
    wind_dir_100m: "Direção 100m",
    gust_100m: "Rajada 100m",
    wave_height_max: "Onda - altura máx",
    visibility: "Visibilidade",
    temp_air: "Temperatura do ar",
    dew_point: "Ponto de orvalho",
    wave_power: "Potência",
    mean_period_swell: "Onda swell - período médio",
    mean_period_sea: "Onda de vento - período médio",
    rel_humidity: "Umidade relativa a 2m",
    precip_min_20km: "Chuva mínima e máxima",
    precip_probability: "Probabilidade de chuva",
    precipitation: "Chuva",
  },
  utils: {
    now: "Agora mesmo",
    minAgo: "Há {{minutes}}min",
    oneHourAgo: "Há 1h",
    hoursAgo: "Há {{hours}}h",
    oneDayAgo: "Há 1 dia",
    daysAgo: "Há {{days}} dias",
    longTimeAgo: "Há muito tempo",
  },
  "404": {
    oopsPageNotFound: "Oops, página não encontrada",
    goToLogin: "Ir para login",
  },
  "500": {
    oopsAnErrorOccurred: "Oops, ocorreu um erro inesperado",
    tryAgain: "Tente novamente",
  },
};
