import { makeAutoObservable } from "mobx";
import { getEnv, getRoot } from "mobx-easy";
import { EnvironmentalType } from "../../../../enum/EnvironmentalType";
import { MacroRegion } from "../../../../enum/MacroRegion";
import { StoreState } from "../../../../enum/StoreState";
import { UserExperience } from "../../../../enum/UserExperience";
import { IMacroRegion } from "../../../../types/IMacroRegion";
import { getMacroRegionOriginFromLatLon } from "../../../../util/macroRegion/getMacroRegionOriginFromLatLon";
import { getGridLimitsFromConfig } from "../../../../util/validadePointInsidePolygon/getGridLimitsFromConfig";
import { validatePointInsidePolygon } from "../../../../util/validadePointInsidePolygon/validatePointInsidePolygon";
import { RootEnv } from "../../../setup/create-store";
import { IStation } from "../../oceanWeatherIntelligences/oceanWeatherIntelligenceModel";
import i18n from "../../../../i18n";
import { IStationRegionId } from "../../../../types/IStationRegion";
import RootStore from "../../../root-store";
import { LatLngExpression } from "leaflet";
import {
  findChartMenuParentId,
  findMenuItemById,
  loadChartMenuData,
} from "../../../../pages/ForecastChart/ForecastChartPanelEnvironmentalButtons/ChartMenuConfig";
import { EnvironmentalVariables } from "../../../../enum/EnvironmentalVariables";
import { VisualizationType } from "enum/VisualizationType";
import axios from "axios";
import { ISensorMeasuredData } from "types/ISensorMeasuredData";
import { IGetSensorMakerLocationsParams } from "services/SensorService";
import RequestCancellationStore from "../../../data/requestCancellationStore/RequestCancellationStore";
import { MetOceanReportRequest } from "pages/ForecastChart/MetOceanReport/MetOceanReportModal";
import { Radar } from "pages/ForecastChart/ForecastChartPanelMap/ForecastChartPanelMap";
import { DateTime } from "luxon";

export default class ForecastChartPageStore {
  state: string = StoreState.DONE;

  messageError: string = "";

  messageSuccess: string = "";

  tabStateActivty: EnvironmentalType = EnvironmentalType.WEATHER;

  stations: IStation[] = [];

  stationsWithDataPostProcessed: IStation[] = [];

  selectedStation: number = 0;

  mapCenterLatitude: number = 0;

  mapCenterLongitude: number = 0;

  mapZoom: number = 10;

  clickMapLatLng: any;

  regionId: number = 0;

  clickMapPointHeatMap: any[][] = [];

  stationInfo: string = "";

  actionCallMeteogramChart: boolean = false;

  macroRegionOrigin: MacroRegion | undefined;

  stationRegionInsightIds: IStationRegionId[] = [];

  insightMode: boolean = false;

  searchedLatLng: LatLngExpression | null = null;

  probabilisticActivityVariable: EnvironmentalVariables | null = null;

  loadFavoriteInsight = false;

  favoriteInsightAlreadyLoaded = false;

  actionCallGetStationsWithSensor: boolean = false;

  sensorMarkerData: ISensorMeasuredData[] = [];

  stateStationsWithSensorData: string = StoreState.DONE;

  actionCallMapClick: boolean = false;

  messageToastNoStationWithSensor: string = "";

  toastNoStationWithSensorId = "toastNoStationWithSensorId";

  requestCancellationStore: RequestCancellationStore;

  statePDFReport: string = StoreState.DONE;

  radarData: Radar[][] = [];

  emptyRadarData: boolean = false;

  lastRadarUpdateDate: string | null = null;

  //for Vattenfall
  vattenfallRegions = [
    MacroRegion.GERMAN_NORTH_SEA,
    MacroRegion.DANISH_NORTH_SEA,
    MacroRegion.SCOTLAND_NORTH_SEA,
    MacroRegion.SOUTHERN_BIGHT_NORTH_SEA,
    MacroRegion.IRISH_SEA,
    MacroRegion.BALTIC_SEA,
  ];

  constructor(requestCancellationStore: RequestCancellationStore) {
    makeAutoObservable(this);

    this.requestCancellationStore = requestCancellationStore;
  }

  setState(value: StoreState) {
    this.state = value;
  }

  setMessageError(value: string) {
    this.messageError = value;
  }

  setMessageSuccess(value: string) {
    this.messageSuccess = value;
  }

  setTabStateActivity(environmentalType: EnvironmentalType) {
    this.tabStateActivty = environmentalType;
  }

  setStations(values: IStation[]) {
    this.stations = values;
  }

  setStationsWithDataPostProcessed(values: IStation[]) {
    this.stationsWithDataPostProcessed = values;
  }

  setSelectedStation(values: number) {
    this.selectedStation = values;
    this.setStationInfo();
  }

  setClickMapLatLng(values: any) {
    this.clickMapLatLng = values;
  }

  setClickMapPointHeatMap(value: any[][]) {
    this.clickMapPointHeatMap = value;
  }

  setMacroRegionOrigin(macroRegion: MacroRegion | undefined) {
    this.macroRegionOrigin = macroRegion;
  }

  setStationRegionInsightIds(value: IStationRegionId[]) {
    this.stationRegionInsightIds = value;
  }

  setInsightMode(value: boolean) {
    this.insightMode = value;
  }

  setSearchedLatLng(value: LatLngExpression | null) {
    this.searchedLatLng = value;
  }

  setProbabilisticActivityVariable(value: EnvironmentalVariables | null) {
    this.probabilisticActivityVariable = value;
  }

  setLoadFavoriteInsight(value: boolean) {
    this.loadFavoriteInsight = value;
  }

  setFavoriteInsightAlreadyLoaded(value: boolean) {
    this.favoriteInsightAlreadyLoaded = value;
  }

  setProbabilisticVariableByActivityTab() {
    if (this.tabStateActivty === EnvironmentalType.WEATHER) {
      this.setProbabilisticActivityVariable(EnvironmentalVariables.WIND_VEL);
    } else if (this.tabStateActivty === EnvironmentalType.WEATHER_TEMPERATURE) {
      this.setProbabilisticActivityVariable(EnvironmentalVariables.TEMP_AIR);
    } else if (this.tabStateActivty === EnvironmentalType.PRECIPITATION) {
      this.setProbabilisticActivityVariable(EnvironmentalVariables.PRECIP);
    } else if (this.tabStateActivty === EnvironmentalType.WAVE) {
      this.setProbabilisticActivityVariable(EnvironmentalVariables.WAVE_HEIGHT);
    } else if (this.tabStateActivty === EnvironmentalType.WAVE_SEA) {
      this.setProbabilisticActivityVariable(
        EnvironmentalVariables.WAVE_HEIGHT_SEA
      );
    } else if (this.tabStateActivty === EnvironmentalType.WAVE_SWELL) {
      this.setProbabilisticActivityVariable(
        EnvironmentalVariables.WAVE_HEIGHT_SWELL
      );
    } else if (this.tabStateActivty === EnvironmentalType.WATER_FLOW) {
      this.setProbabilisticActivityVariable(EnvironmentalVariables.WATER_LEVEL);
    }
  }

  setActionCallGetStationsWithSensor() {
    this.actionCallGetStationsWithSensor =
      !this.actionCallGetStationsWithSensor;
  }

  setSensorMarkerData(sensorMarkers: ISensorMeasuredData[]) {
    this.sensorMarkerData = sensorMarkers;
  }

  setStateStationsWithSensorData(value: StoreState) {
    this.stateStationsWithSensorData = value;
  }

  setActionCallMapClick() {
    this.actionCallMapClick = !this.actionCallMapClick;
  }

  setMessageToastNoStationWithSensor(value: string) {
    this.messageToastNoStationWithSensor = value;
  }

  setStatePDFReport(value: StoreState) {
    this.statePDFReport = value;
  }

  getStationById(id: number, macroRegionOrigin?: string) {
    return this.stations.find(
      (value) =>
        value.station_id === id &&
        value.macro_region_origin === macroRegionOrigin
    );
  }

  getStationByIdAndRegionId(id: number, regionId: number) {
    return this.stations.find(
      (value) => value.station_id === id && value.region_id === regionId
    );
  }

  stationSavedByUser(id: number) {
    const station = this.stations.find((value) => value.station_id === id);
    if (station) {
      return station.station_save_by_user || false;
    }
    return false;
  }

  stationWithHistoricalData(id: number) {
    const station = this.stations.find((value) => value.station_id === id);
    if (station) {
      return station.data_history || false;
    }
    return false;
  }

  setMapCenter(): void {
    const foundStation = this.stations.find(
      (value) => value.station_id === this.selectedStation
    );
    if (foundStation) {
      this.setMapCenterLatitude(foundStation.station_lat);
      this.setMapCenterLongitude(foundStation.station_lon);
    }
  }

  setMapCenterLatitude(value: number) {
    this.mapCenterLatitude = value;
  }

  setMapCenterLongitude(value: number) {
    this.mapCenterLongitude = value;
  }

  setMapZoom(value: number) {
    this.mapZoom = value;
  }

  setRegionId(regionId: number) {
    this.regionId = regionId;
  }

  setStationInfo() {
    this.stationInfo = this.getStationInfo() || "";
  }

  setActionCallMeteogramChart(value: boolean) {
    this.actionCallMeteogramChart = value;
  }

  setRadarData(value: Radar[][]) {
    this.radarData = value;
  }

  setEmptyRadarData(value: boolean) {
    this.emptyRadarData = value;
  }

  setLastRadarUpdateDate(value: string | null) {
    this.lastRadarUpdateDate = value;
  }

  updateStationDataHistory(
    stationId: number,
    regionId: number,
    dataHistory: boolean
  ) {
    const stations = [...this.stations];
    const foundStationIndex = stations.findIndex(
      (station) =>
        station.station_id === stationId && station.region_id === regionId
    );
    if (foundStationIndex >= 0) {
      stations[foundStationIndex].data_history = dataHistory;
      this.setStations(stations);
    }
  }

  updateMacroRegionOrigin(values: any, companyMacroRegions: IMacroRegion[]) {
    if (values) {
      const macroRegionOrigin = getMacroRegionOriginFromLatLon(
        values.lat,
        values.lng
      );
      const isAllowed = this.validateMacroRegion(
        companyMacroRegions,
        macroRegionOrigin
      );
      if (isAllowed) {
        this.setMacroRegionOrigin(
          getMacroRegionOriginFromLatLon(values.lat, values.lng)
        );
      }
    }
  }

  reset() {
    this.setStations([]);
    this.setRegionId(0);
    this.setSelectedStation(0);
    this.setTabStateActivity(EnvironmentalType.WATER_FLOW);
    this.setState(StoreState.DONE);
    this.setClickMapLatLng(undefined);
    this.stationInfo = "";
    this.setMapCenterLatitude(0);
    this.setMapCenterLongitude(0);
    this.setMacroRegionOrigin(undefined);
    this.setStationRegionInsightIds([]);
    this.setProbabilisticActivityVariable(null);
    this.setStateStationsWithSensorData(StoreState.DONE);
    this.setMessageToastNoStationWithSensor("");
    this.setStatePDFReport(StoreState.DONE);
    this.setRadarData([]);
    this.setEmptyRadarData(false);
    this.setLastRadarUpdateDate(null);
  }

  resetAllCharts() {
    const {
      dataStores: {
        forecastChartMeteogramStore,
        analyticsChartStore,
        forecastChartProbabilisticStore,
      },
    } = getRoot<RootStore>();
    forecastChartMeteogramStore.resetChartMeteogramData();
    analyticsChartStore.resetChartMeteogramData();
    forecastChartProbabilisticStore.setProbabilisticForecastData(null);
  }

  async getStationsForecastChartAPI(environmental_type: EnvironmentalType) {
    const { atmOceanService } = getEnv<RootEnv>();
    const {
      dataStores: { forecastChartPageStore },
    } = getRoot<RootStore>();

    this.setStations([]);
    this.setState(StoreState.PEDDING);
    try {
      this.setTabStateActivity(environmental_type);
      const stationsResult: IStation[] =
        await atmOceanService.getStationsForecastChart(environmental_type);

      if (stationsResult.length > 0) {
        this.setStations(stationsResult);

        const found = stationsResult.find(
          (value) => value.station_id === this.selectedStation
        );
        if (stationsResult[0].region_id !== undefined) {
          this.setRegionId(stationsResult[0].region_id);
        }
        if (!found) {
          this.setSelectedStation(stationsResult[0].station_id);

          this.setClickMapLatLng({
            lat: parseFloat(stationsResult[0].station_lat.toString()),
            lng: parseFloat(stationsResult[0].station_lon.toString()),
          });
          this.setActionCallMeteogramChart(!this.actionCallMeteogramChart);
        } else {
          this.setSelectedStation(found.station_id);

          this.setActionCallMeteogramChart(!this.actionCallMeteogramChart);
        }
        forecastChartPageStore.setStationInfo();
      } else {
        this.setSelectedStation(0);
      }

      this.setMapCenter();
      this.setState(StoreState.DONE);
    } catch (error) {
      this.setState(StoreState.ERROR);
      this.setMessageError(
        i18n.t(
          "forecastChart.forecastChartPage.errorToGetDataStationMeteogramChartType"
        )
      );
    }
  }

  async getValidStationsForecastChartAPI(
    environmental_type: EnvironmentalType
  ) {
    const { atmOceanService } = getEnv<RootEnv>();
    this.setStationsWithDataPostProcessed([]);

    this.setState(StoreState.PEDDING);
    try {
      const stationsResult: IStation[] =
        await atmOceanService.getStationsMeteogram(environmental_type);

      if (stationsResult.length > 0) {
        this.setStationsWithDataPostProcessed(stationsResult);
      }
      this.setState(StoreState.DONE);
    } catch (error) {
      this.setState(StoreState.ERROR);
      this.setMessageError(
        i18n.t(
          "forecastChart.forecastChartPage.errorToGetDataValidStationMeteogramChartType"
        )
      );
    } finally {
      setTimeout(() => {
        this.setMessageError("");
        this.setState(StoreState.DONE);
      }, 2000);
    }
  }

  async saveStationByUserAPI(
    description: string,
    latitude: number,
    longitude: number,
    macroRegionOrigin: string
  ) {
    this.setState(StoreState.DONE);
    const { atmOceanService } = getEnv<RootEnv>();
    const msgError = i18n.t(
      "forecastChart.forecastChartPage.errorToSaveVirtualStation"
    );
    try {
      const isStationDescriptionAlreadyExist =
        await atmOceanService.hasStationDescription(
          description,
          macroRegionOrigin
        );
      if (isStationDescriptionAlreadyExist) {
        this.setMessageError(
          `${i18n.t(
            "forecastChart.forecastChartPage.thereIsAlreadyStationName"
          )} ${description}`
        );
        this.setState(StoreState.ERROR);
      } else {
        const stationInserted = await atmOceanService.saveStationByUser(
          description,
          latitude,
          longitude,
          macroRegionOrigin
        );
        if (stationInserted) {
          const updatedStations = [...this.stations, stationInserted];

          this.setStations(updatedStations);
          this.setSelectedStation(stationInserted.station_id);

          this.setStationInfo();
          this.setMessageSuccess(
            i18n.t("forecastChart.forecastChartPage.successSationHasSaved")
          );
          this.setState(StoreState.SUCCESS);
        } else {
          this.setMessageError(msgError);
          this.setState(StoreState.ERROR);
        }
      }
    } catch (error) {
      this.setMessageError(msgError);
      this.setState(StoreState.ERROR);
    }

    setTimeout(() => {
      this.setState(StoreState.DONE);
    }, 5000);
  }

  async addLogUserExperienceAPI(
    userExperience: UserExperience,
    details?: string
  ) {
    const { logServices } = getEnv<RootEnv>();
    logServices.addLogUserExperience(userExperience, details);
  }

  async getGeoLocationClickMapAPI() {
    const { atmOceanService } = getEnv<RootEnv>();

    try {
      this.setClickMapPointHeatMap([]);
      this.setState(StoreState.PEDDING);
      const result = await atmOceanService.getGeoLocationClickMap();

      if (result.length > 0) {
        this.setClickMapPointHeatMap(result);
      }
    } catch (error) {
      console.log(Error);
    } finally {
      this.setState(StoreState.DONE);
    }
  }

  async removeStationByUserAPI(stationId: number, regionId: number) {
    this.setState(StoreState.DONE);
    const { atmOceanService } = getEnv<RootEnv>();
    const msgError = i18n.t(
      "forecastChart.forecastChartPage.errorToRemoveStation"
    );

    const stationSelected = this.getStationByIdAndRegionId(stationId, regionId);

    if (stationSelected) {
      try {
        let stationRemoved: boolean = false;

        stationRemoved = await atmOceanService.removeStationByUser(
          stationSelected.station_id,
          stationSelected.region_id!
        );

        if (stationRemoved) {
          const stationToRemoveIndex = this.stations.indexOf(stationSelected);
          this.stations.splice(stationToRemoveIndex, 1);
          if (stationId === this.selectedStation) {
            this.setSelectedStation(0);
          }
        } else {
          this.setMessageError(msgError);
          this.setState(StoreState.ERROR);
        }
      } catch (error: any) {
        this.setState(StoreState.ERROR);

        if (error.hasOwnProperty("response")) {
          if (error.response.status === 401) {
            this.setMessageError(
              i18n.t("forecastChart.forecastChartFormStation.unauthorizedUser")
            );
          } else if (error.response?.status === 403) {
            this.setMessageError(i18n.t("youCanOnlyChangeItems"));
          }
        } else {
          this.setMessageError(msgError);
        }
      } finally {
        setTimeout(() => {
          this.setMessageError("");
          this.setState(StoreState.DONE);
        }, 1000);
      }
    } else {
      this.setMessageError(msgError);
      this.setState(StoreState.ERROR);

      setTimeout(() => {
        this.setMessageError("");
        this.setState(StoreState.DONE);
      }, 2000);
    }
  }

  validateLatLonGrid(lat: number, lng: number, macroRegionOrigin: MacroRegion) {
    const chartMenuData = loadChartMenuData();
    let activeEnvironmentalType = findChartMenuParentId(
      chartMenuData,
      this.tabStateActivty
    );

    if (!activeEnvironmentalType) {
      const _menuParent = findMenuItemById(chartMenuData, this.tabStateActivty);
      if (!_menuParent) return false;
      activeEnvironmentalType = _menuParent.id;
    }

    const polygon = getGridLimitsFromConfig(
      activeEnvironmentalType,
      macroRegionOrigin
    );
    if (!polygon) return false;
    return validatePointInsidePolygon([lat, lng], polygon);
  }

  getStationInfo() {
    const station = this.getStationByIdAndRegionId(
      this.selectedStation,
      this.regionId
    );
    if (station) {
      let lat = parseFloat(station.station_lat.toString()).toFixed(4);
      let lng = parseFloat(station.station_lon.toString()).toFixed(4);

      return `${station.station_name} (${lat}, ${lng})`;
    } else if (this.clickMapLatLng) {
      const latLng = this.clickMapLatLng;
      let lat = parseFloat(latLng.lat.toString()).toFixed(4);
      let lng = parseFloat(latLng.lng.toString()).toFixed(4);
      if (latLng) {
        return `${lat} , ${lng} `;
      }
      return "";
    }
  }

  validateMacroRegion(
    companyMacroRegions: IMacroRegion[],
    macroRegionOrigin: MacroRegion | undefined
  ) {
    if (!macroRegionOrigin || !companyMacroRegions) {
      return;
    }
    if (
      !companyMacroRegions.find(
        (macroRegion) => macroRegion.origin === macroRegionOrigin
      )
    ) {
      return;
    }
    return true;
  }

  getStationName() {
    const station = this.getStationById(
      this.selectedStation,
      this.macroRegionOrigin!
    );

    if (station) {
      let lat = parseFloat(station.station_lat.toString()).toFixed(4);
      let lng = parseFloat(station.station_lon.toString()).toFixed(4);

      return `${station.station_name} (${lat}, ${lng})`;
    }
  }

  isStationRegionHasInisight() {
    return this.stationRegionInsightIds?.some(
      (item) =>
        `${item.stationId}-${item.regionId}` ===
        `${this.selectedStation}-${this.regionId}`
    );
  }

  async handleOnChangeTabMeteogram(environmentalType: EnvironmentalType) {
    const {
      dataStores: {
        regionStore,
        forecastChartMeteogramStore,
        analyticsChartStore,
        forecastChartPageStore,
      },
    } = getRoot<RootStore>();

    this.setTabStateActivity(environmentalType);
    analyticsChartStore.setIsLastChartDateGreaterThan3Hours(false);

    if (
      forecastChartMeteogramStore.visualizationType === VisualizationType.SENSOR
    ) {
      forecastChartPageStore.setActionCallGetStationsWithSensor();

      return;
    }

    if (forecastChartPageStore.selectedStation > 0) {
      forecastChartMeteogramStore.setOpenMeteogram(true);
    }

    if (this.clickMapLatLng) {
      this.updateMacroRegionOrigin(
        this.clickMapLatLng,
        regionStore.macroRegions
      );
      if (this.macroRegionOrigin) {
        if (
          this.validateLatLonGrid(
            this.clickMapLatLng.lat,
            this.clickMapLatLng.lng,
            this.macroRegionOrigin
          )
        ) {
          forecastChartMeteogramStore.setIsChartDataEmpty(false);
          forecastChartMeteogramStore.setVisibleMeteogramChart(true);
          this.setActionCallMeteogramChart(!this.actionCallMeteogramChart);
        } else {
          forecastChartMeteogramStore.setState(StoreState.DONE);
          forecastChartMeteogramStore.resetChartMeteogramData();
          forecastChartMeteogramStore.setIsChartDataEmpty(true);
          analyticsChartStore.setChartMeteogramDataSeries([]);
        }
      } else {
        forecastChartMeteogramStore.setState(StoreState.DONE);
        forecastChartMeteogramStore.setIsChartDataEmpty(true);
      }
    }
  }

  updateOpenMeteogramBySelectedSensor(sensorData: ISensorMeasuredData[]) {
    const {
      dataStores: { forecastChartMeteogramStore },
    } = getRoot<RootStore>();
    const stationId = this.selectedStation;
    const regionId = this.regionId;

    const selectedSensorMarker = sensorData?.find(
      (s) => s.station_id === stationId && s.region_id === regionId
    );

    if (
      !selectedSensorMarker &&
      forecastChartMeteogramStore.visualizationType === VisualizationType.SENSOR
    ) {
      this.setSelectedStation(0);
      this.setRegionId(0);
      this.stationInfo = "";
      this.setClickMapLatLng(undefined);
      forecastChartMeteogramStore.setOpenMeteogram(false);
    } else {
      forecastChartMeteogramStore.setOpenMeteogram(true);
    }
  }

  async getStationsWithSensorData({
    envType,
    macroRegionId,
    controller,
  }: IGetSensorMakerLocationsParams) {
    const { sensorService } = getEnv<RootEnv>();
    const {
      dataStores: { analyticsChartStore, forecastChartMeteogramStore },
    } = getRoot<RootStore>();

    this.setMessageToastNoStationWithSensor("");
    this.setSensorMarkerData([]);
    this.setStateStationsWithSensorData(StoreState.PEDDING);

    try {
      if (controller) {
        this.requestCancellationStore.addController(controller);
      }

      const data = await sensorService.getSensorMakerLocations({
        envType,
        macroRegionId,
        controller,
      });

      this.setSensorMarkerData(data);
      this.updateOpenMeteogramBySelectedSensor(data);

      analyticsChartStore.setActionCallGetMeasuredChart();
      this.setStateStationsWithSensorData(StoreState.DONE);
    } catch (e: any) {
      if (axios.isCancel(e)) {
        console.log("axios request cancelled");
      } else {
        if (e.hasOwnProperty("response")) {
          if (e.response.status === 404) {
            const msgError = i18n.t(
              "forecastChart.forecastChartPage.noSensorWasFound"
            );

            this.setMessageToastNoStationWithSensor(msgError);
            this.setStateStationsWithSensorData(StoreState.DONE);
            this.setSelectedStation(0);
            this.setRegionId(0);
            this.stationInfo = "";
            this.setClickMapLatLng(undefined);
            forecastChartMeteogramStore.setOpenMeteogram(false);

            analyticsChartStore.setActionCallGetMeasuredChart();
          } else {
            this.setStateStationsWithSensorData(StoreState.ERROR);
            console.error(e);
          }
        }
      }
    } finally {
      if (controller) {
        this.setStateStationsWithSensorData(StoreState.DONE);
        this.requestCancellationStore.removeController(controller);
      }
    }
  }

  async getPDFReport(MetOceanReportData: MetOceanReportRequest[]) {
    const { metOceanService } = getEnv<RootEnv>();

    this.setStatePDFReport(StoreState.PEDDING);
    try {
      const queryString = encodeURIComponent(
        JSON.stringify(MetOceanReportData)
      );

      const blob = await metOceanService.getPDFReport(queryString);

      const url = URL.createObjectURL(blob);

      this.setStatePDFReport(StoreState.DONE);
      return url;
    } catch (error) {
      console.error("Erro:", error);
      this.setStatePDFReport(StoreState.ERROR);
    }
  }

  async getRadarData() {
    const { radarService } = getEnv<RootEnv>();

    try {
      const response = await radarService.getRadarData();

      if (!response.data?.radar?.length) return;

      const radarData: Radar[][] = response.data.radar;
      const imbitubaLocation = "mi";

      const radar = radarData[0].find(
        (r: Radar) => r.localidade === imbitubaLocation
      );

      if (!radar) {
        this.setEmptyRadarData(true);
        return;
      }

      if (radar.localidade !== imbitubaLocation || !radar.path) {
        this.setEmptyRadarData(true);
      } else {
        if (radar.data) {
          const dateTime = DateTime.fromFormat(
            radar.data,
            "yyyy-MM-dd HH:mm:ss",
            {
              zone: "utc",
            }
          );

          const locale = i18n.language === "en" ? "en-US" : "pt-BR";

          const formatted = dateTime
            .setZone("America/Sao_Paulo")
            .setLocale(locale)
            .toFormat("dd/MM/yy - HH:mm");

          this.setLastRadarUpdateDate(formatted);
        }
        this.setRadarData([[radar]]);
      }

      return response;
    } catch (error) {
      console.error("Erro:", error);
    }
  }
}
